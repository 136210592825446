
var allPaths=[
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ], [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.1178751,
                    31.54625284
                ],
                [
                    -97.11785146,
                    31.54627198
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_308",
            "ElevatorE_": ""
        }
    },   {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11783384,
                    31.5462182
                ],
                [
                    -97.11780067,
                    31.54624155
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_306",
            "ElevatorE_": ""
        }
    },

    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11802183,
                    31.54637401
                ],
                [
                    -97.11802075,
                    31.54637309
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_311",
            "ElevatorE_": ""
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_314",
            "ElevatorE_": ""
        }
    },  {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11820899,
                    31.54653209
                ],
                [
                    -97.11819673,
                    31.5465438
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_316",
            "ElevatorE_": ""
        }
    }
    ,
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.1182806,
                    31.54659293
                ],
                [
                    -97.11827147,
                    31.54660712
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_318",
            "ElevatorE_": ""
        }
    }
    ,
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11835604,
                    31.54665688
                ],
                [
                    -97.11834402,
                    31.54666858
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_320",
            "ElevatorE_": ""
        }
    },  {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11836592,
                    31.54666716
                ],
                [
                    -97.11838101,
                    31.54665782
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_319",
            "ElevatorE_": ""
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11828133,
                    31.54659597
                ],
                [
                    -97.11829078,
                    31.54658147
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_317",
            "ElevatorE_": ""
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.1182261,
                    31.54654989
                ],
                [
                    -97.11823901,
                    31.54653767
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_315",
            "ElevatorE_": ""
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183432,
                    31.54587857
                ],
                [
                    -97.11836337,
                    31.54586486
                ],
                [
                    -97.11842832,
                    31.54592547
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11795859,
                    31.54632006
                ],
                [
                    -97.11817561,
                    31.54650676
                ],
                [
                    -97.11819079,
                    31.54649687
                ]
            ]
        },
        "id": "ccc891c5-13ac-4be0-bed7-3e9cc97fbbb8",
        "properties": {
            "name": "ElevatorE_309.02",
            "ElevatorE_": ""
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11834632,
                    31.54588191
                ],
                [
                    -97.11836527,
                    31.54586397
                ],
                [
                    -97.11843241,
                    31.5459272
                ],
                [
                    -97.11812117,
                    31.54618696
                ],
                [
                    -97.1181314,
                    31.54619936
                ]
            ]
        },
        "id": "ad10a959-571a-4c2a-abd3-f9ff3041dcde",
        "properties": {
            "name": "ElevatorE_301"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183455,
                    31.54588102
                ],
                [
                    -97.11836195,
                    31.54586711
                ],
                [
                    -97.11842136,
                    31.54593315
                ],
                [
                    -97.11809956,
                    31.54620577
                ],
                [
                    -97.11811301,
                    31.54621568
                ]
            ]
        },
        "id": "de944379-70a2-4026-b17d-abeb0cf169d1",
        "properties": {
            "name": "ElevatorE_302"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183455,
                    31.54588102
                ],
                [
                    -97.11836195,
                    31.54586711
                ],
                [
                    -97.11842136,
                    31.54593315
                ],
                [
                    -97.11809956,
                    31.54620577
                ],
                [
                    -97.11807874,
                    31.54622461
                ],
                [
                    -97.11809122,
                    31.54623503
                ]



            ]
        },
        "id": "de944379-70a2-4026-b17d-abeb0cf169d1",
        "properties": {
            "name": "ElevatorE_303"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183455,
                    31.54588102
                ],
                [
                    -97.11836195,
                    31.54586711
                ],
                [
                    -97.11842136,
                    31.54593315
                ],
                [
                    -97.11809956,
                    31.54620577
                ],
                [
                    -97.11805831,
                    31.54624287
                ],
                [
                    -97.11806951,
                    31.5462543
                ]




            ]
        },
        "id": "de944379-70a2-4026-b17d-abeb0cf169d1",
        "properties": {
            "name": "ElevatorE_304"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183455,
                    31.54588102
                ],
                [
                    -97.11836195,
                    31.54586711
                ],
                [
                    -97.11842136,
                    31.54593315
                ],
                [
                    -97.11809956,
                    31.54620577
                ],
                [
                    -97.11803973,
                    31.5462608
                ],
                [
                    -97.11805532,
                    31.54626689
                ]

            ]
        },
        "id": "de944379-70a2-4026-b17d-abeb0cf169d1",
        "properties": {
            "name": "ElevatorE_305"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11834379,
                    31.5458792
                ],
                [
                    -97.11836697,
                    31.54586397
                ],
                [
                    -97.11842922,
                    31.54592348
                ],
                [
                    -97.11801384,
                    31.54627431
                ],
                [
                    -97.11800088,
                    31.54626294
                ]
            ]
        },
        "id": "ed95061f-da37-408b-b827-bce08d8a8758",
        "properties": {
            "name": "ElevatorE_307"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11834519,
                    31.5458807
                ],
                [
                    -97.11838959,
                    31.54584428
                ]
            ]
        },
        "id": "16505ca0-be2e-4eb6-b186-cc5310abdd06",
        "properties": {
            "name": "ElevatorE_353"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11834575,
                    31.5458813
                ],
                [
                    -97.11837316,
                    31.54585523
                ],
                [
                    -97.11843228,
                    31.5459059
                ],
                [
                    -97.11848269,
                    31.54586857
                ],
                [
                    -97.11846699,
                    31.54583365
                ]
            ]
        },
        "id": "02f9d098-075b-4191-8738-4221b9520bb3",
        "properties": {
            "name": "ElevatorE_352"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1183437,
                    31.5458791
                ],
                [
                    -97.1183764,
                    31.54585995
                ],
                [
                    -97.11843564,
                    31.54591348
                ],
                [
                    -97.1184923,
                    31.54585757
                ]
            ]
        },
        "id": "0a44adc9-8c33-4219-9597-21edda18553f",
        "properties": {
            "name": "ElevatorE_351"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11834491,
                    31.5458804
                ],
                [
                    -97.1183679,
                    31.54586143
                ],
                [
                    -97.11842714,
                    31.54592109
                ],
                [
                    -97.11834116,
                    31.54599192
                ],
                [
                    -97.118326,
                    31.54598025
                ]
            ]
        },
        "id": "e458b7eb-6df1-4e5c-b4f9-2ad5a412a3d9",
        "properties": {
            "name": "ElevatorE_355"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11834491,
                    31.5458804
                ],
                [
                    -97.1183679,
                    31.54586143
                ],
                [
                    -97.11842714,
                    31.54592109
                ],
                [
                    -97.11834116,
                    31.54599192
                ],
                [
                    -97.11825268,
                    31.54606684
                ],
                [
                    -97.1182394,
                    31.54605555
                ]
            ]
        },
        "id": "e458b7eb-6df1-4e5c-b4f9-2ad5a412a3d9",
        "properties": {
            "name": "ElevatorE_399.02"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11834566,
                    31.5458812
                ],
                [
                    -97.11836217,
                    31.54586991
                ],
                [
                    -97.11843571,
                    31.5459426
                ]
            ]
        },
        "id": "67b84365-750c-43b0-9977-a5e403899064",
        "properties": {
            "name": "ElevatorE_354"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11808855,
                    31.54641036
                ],
                [
                    -97.11807773,
                    31.54642154
                ],
                [
                    -97.1178388,
                    31.5462225
                ],
                [
                    -97.1178064,
                    31.54624738
                ]
            ]
        },
        "id": "044987a5-38cd-45c9-a4a9-a3505554bb4d",
        "properties": {
            "name": "ElevatorA_306"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11808538,
                    31.54640767
                ],
                [
                    -97.11807371,
                    31.54641815
                ],
                [
                    -97.11790753,
                    31.54628029
                ],
                [
                    -97.11787725,
                    31.54630805
                ]
            ]
        },
        "id": "47120cc8-074f-4ce5-b8b2-360a0b8bdaa7",
        "properties": {
            "name": "ElevatorA_308"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11808939,
                    31.54641107
                ],
                [
                    -97.11806531,
                    31.54643246
                ]
            ]
        },
        "id": "2728559f-1eb8-4cfa-b132-57a3cecb4711",
        "properties": {
            "name": "ElevatorA_311"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11808247,
                    31.54640522
                ],
                [
                    -97.11806979,
                    31.54641596
                ],
                [
                    -97.11813991,
                    31.54647417
                ],
                [
                    -97.11811676,
                    31.54649512
                ]
            ]
        },
        "id": "063cca93-2ddf-4bb1-9390-49e8f376e28e",
        "properties": {
            "name": "ElevatorA_314"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.11809067,
                    31.54641215
                ],
                [
                    -97.11807718,
                    31.54642403
                ],
                [
                    -97.11821084,
                    31.54653314
                ],
                [
                    -97.11818592,
                    31.5465538
                ]
            ]
        },
        "id": "93bd2699-605c-4482-ad69-6fff7a730b69",
        "properties": {
            "name": "ElevatorA_316"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807023,
                    31.5464181
                ],
                [
                    -97.1182854,
                    31.54659794
                ],
                [
                    -97.11827052,
                    31.54660631
                ]
            ]
        },
        "id": "cef5d399-5e97-4776-a13e-23d5e531b0a2",
        "properties": {
            "name": "ElevatorA_318"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.1180713,
                    31.54641674
                ],
                [
                    -97.11834708,
                    31.54664983
                ],
                [
                    -97.11833113,
                    31.54665766
                ]
            ]
        },
        "id": "c083d8b4-7cd6-4006-8d7b-73cd7597136d",
        "properties": {
            "name": "ElevatorA_320"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807504,
                    31.54641835
                ],
                [
                    -97.11834738,
                    31.54665207
                ],
                [
                    -97.11835906,
                    31.54663925
                ]
            ]
        },
        "id": "34fd3235-81dc-474c-9e17-18592e2f5d82",
        "properties": {
            "name": "ElevatorA_319"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807383,
                    31.5464188
                ],
                [
                    -97.11828871,
                    31.54659699
                ],
                [
                    -97.11831184,
                    31.54657883
                ]
            ]
        },
        "id": "eb3b04d0-3247-4bb0-87eb-b9fa4e34b191",
        "properties": {
            "name": "ElevatorA_317"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.1180746,
                    31.54642026
                ],
                [
                    -97.11822646,
                    31.54654768
                ],
                [
                    -97.11825187,
                    31.54653001
                ]
            ]
        },
        "id": "2e08895d-8f31-4403-a35f-8f44c8a5d98e",
        "properties": {
            "name": "ElevatorA_315"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807212,
                    31.54641683
                ],
                [
                    -97.11816778,
                    31.54649941
                ],
                [
                    -97.11819934,
                    31.54648581
                ]
            ]
        },
        "id": "8c8cb671-2df2-4449-b11d-1558fbdd0ce9",
        "properties": {
            "name": "ElevatorA_399.02"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807495,
                    31.54641708
                ],
                [
                    -97.11796197,
                    31.54631852
                ],
                [
                    -97.11803392,
                    31.54625874
                ],
                [
                    -97.11805518,
                    31.54626701
                ]
            ]
        },
        "id": "152c4778-78ac-4e7c-8580-f81e7085e9fd",
        "properties": {
            "name": "ElevatorA_305"
        }
    },
    ,
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807498,
                    31.54641999
                ],
                [
                    -97.11796537,
                    31.54632136
                ],
                [
                    -97.11805675,
                    31.54624462
                ],
                [
                    -97.11807025,
                    31.54625363
                ]
            ]
        },
        "id": "3f45aadf-8aaf-4634-a80d-e2dd99933a34",
        "properties": {
            "name": "ElevatorA_304"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807498,
                    31.54641999
                ],
                [
                    -97.11796537,
                    31.54632136
                ],
                [
                    -97.11808422,
                    31.54622336
                ],
                [
                    -97.1180942,
                    31.54623238
                ]

            ]
        },
        "id": "3f45aadf-8aaf-4634-a80d-e2dd99933a34",
        "properties": {
            "name": "ElevatorA_303"
        }
    },{
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807498,
                    31.54641999
                ],
                [
                    -97.11796537,
                    31.54632136
                ],
                [
                    -97.11808422,
                    31.54622336
                ],
                [
                    -97.11810247,
                    31.54620518
                ],
                [
                    -97.11811189,
                    31.54621668
                ]

            ]
        },
        "id": "3f45aadf-8aaf-4634-a80d-e2dd99933a34",
        "properties": {
            "name": "ElevatorA_302"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.1180711,
                    31.54641485
                ],
                [
                    -97.11796392,
                    31.54632299
                ],
                [
                    -97.11812072,
                    31.54618633
                ],
                [
                    -97.11813104,
                    31.54619968
                ]
            ]
        },
        "id": "3c35d85c-a2d1-45d0-855d-f91767ada2b2",
        "properties": {
            "name": "ElevatorA_301"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807333,
                    31.54641927
                ],
                [
                    -97.11796361,
                    31.54632115
                ],
                [
                    -97.11819997,
                    31.54611699
                ],
                [
                    -97.11818454,
                    31.54610325
                ]
            ]
        },
        "id": "c1c6736b-8e09-485c-99a4-930b6a2b3e4a",
        "properties": {
            "name": "ElevatorA_360"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807421,
                    31.5464211
                ],
                [
                    -97.11796409,
                    31.54632382
                ],
                [
                    -97.11832267,
                    31.54601355
                ],
                [
                    -97.1183067,
                    31.54599704
                ]
            ]
        },
        "id": "8cbaadda-6cee-4252-a5e2-ab3f259b868f",
        "properties": {
            "name": "ElevatorA_355"
        }
    },
    // {
    //     "type": "Feature",
    //     "geometry": {
    //         "type": "LineString",
    //         "coordinates": [
    //             [
    //                 -97.1180858,
    //                 31.54640803
    //             ],
    //             [
    //                 -97.11807069,
    //                 31.54641809
    //             ],
    //             [
    //                 -97.11796615,
    //                 31.54632382
    //             ],
    //             [
    //                 -97.1182587,
    //                 31.54606409
    //             ],
    //             [
    //                 -97.11824283,
    //                 31.54605257
    //             ]
    //         ]
    //     },
    //     "id": "436864e2-74f9-45d9-b0bd-8f53c1c3d22e",
    //     "properties": {
    //         "name": "ElevatorA_399.02"
    //     }
    // },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807377,
                    31.54642131
                ],
                [
                    -97.1179626,
                    31.54632326
                ],
                [
                    -97.11846465,
                    31.54589056
                ],
                [
                    -97.11844774,
                    31.54587625
                ]
            ]
        },
        "id": "1715964e-cad4-4102-a4b4-327de84fc961",
        "properties": {
            "name": "ElevatorA_353"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807377,
                    31.54642131
                ],
                [
                    -97.1179626,
                    31.54632326
                ],
                [
                    -97.11846465,
                    31.54589056
                ],
                [
                    -97.11848107,
                    31.54584679
                ]

            ]
        },
        "id": "1715964e-cad4-4102-a4b4-327de84fc961",
        "properties": {
            "name": "ElevatorA_352"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.1180716,
                    31.54641795
                ],
                [
                    -97.11796199,
                    31.54632099
                ],
                [
                    -97.11849412,
                    31.54585896
                ]
            ]
        },
        "id": "fa2a31db-3818-4501-a633-396e7b5bdbf1",
        "properties": {
            "name": "ElevatorA_351"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "LineString",
            "coordinates": [
                [
                    -97.1180858,
                    31.54640803
                ],
                [
                    -97.11807246,
                    31.54641985
                ],
                [
                    -97.11796642,
                    31.54632396
                ],
                [
                    -97.11847037,
                    31.5458872
                ],
                [
                    -97.11854445,
                    31.54595514
                ],
                [
                    -97.11852454,
                    31.5459708
                ]
            ]
        },
        "id": "c327fd1a-585f-480f-b6a8-9435d5ac8045",
        "properties": {
            "name": "ElevatorA_354"
        }
    }




]

export default allPaths;