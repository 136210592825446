//
// var outline =
//     {
//         "type": "Feature",
//         "geometry": {
//             "type": "LineString",
//             "coordinates": [
//                 [
//                     -97.1188147,
//                     31.54635075
//                 ],
//                 [
//                     -97.11839369,
//                     31.54671265
//                 ],
//                 [
//                     -97.11836614,
//                     31.54668732
//                 ],
//                 [
//                     -97.11830178,
//                     31.54673685
//                 ],
//                 [
//                     -97.1177254,
//                     31.54624092
//                 ],
//                 [
//                     -97.11778422,
//                     31.54619434
//                 ],
//                 [
//                     -97.11774512,
//                     31.54616436
//                 ],
//                 [
//                     -97.11815396,
//                     31.54580348
//                 ],
//                 [
//                     -97.11828282,
//                     31.54590523
//                 ],
//                 [
//                     -97.11844122,
//                     31.54577862
//                 ],
//                 [
//                     -97.11885205,
//                     31.54612484
//                 ],
//                 [
//                     -97.11870527,
//                     31.54625119
//                 ],
//                 [
//                     -97.11881179,
//                     31.54635043
//                 ]
//             ]
//         },
//         "id": "ed81f5b9-577d-4df0-aeaf-a9ef8fe65250",
//         "properties": {
//             "name": ""
//         }
// }
var outline ={
    "type": "Feature",
    "geometry": {
        "type": "LineString",
        "coordinates": [
            [
                -97.11268642,
                31.5433138
            ],
            [
                -97.11250135,
                31.54347267
            ],
            [
                -97.11238333,
                31.54337209
            ],
            [
                -97.11257243,
                31.54321436
            ],
            [
                -97.11268642,
                31.54331837
            ]
        ]
    },
    "id": "e4027371-09a6-4bf5-ae60-04aa47f35d08",
    "properties": {
        "aa": "",
        "name": "aa"
    }
}

export default outline;